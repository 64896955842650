<template>
  <div class="petitionRight">
    <!-- 重点人员数量-->
    <div class="keynoteNumber">
      <div class="title">
        <h1>各街道重点人员数量</h1>
      </div>
      <div id="keynoteNumberCom"></div>
    </div>
    <!--  各街道未办结事件数量    -->
    <div class="notDoneNumber">
      <div class="title" @click="unsettled">
        <h1>各街道未办结事件数量</h1>
      </div>
      <div id="notDoneNumberCom"></div>
    </div>
    <!--   各街道信访事件来源信息-->
    <div class="sourceInfo">
      <div class="title" @click="tcypyj">
        <h1>各街道信访事件来源信息</h1>
      </div>
      <div id="sourceInfoCom"></div>
    </div>
    <!-- 各街道事件列表 -->
    <el-dialog :visible.sync="unsettledShow" :custom-class="customDialogClass">
      <div class="wgy">各街道事件列表</div>
      <div class="inspector">
        <div v-for="(item, index) in inspector" :key="index" :class="index + 1 == inspectorIndex ? 'active' : ''"
          @click="inspectorClick(index + 1)">{{ item }}</div>
      </div>
      <!-- <dv-decoration-5 style="width:100px;height:15px;margin-left: 4%;margin-top: -2%;" /> -->
      <!-- 表格 -->
      <div class="apply applyHead applyWid">
        <p>姓名</p>
        <p>电话</p>
        <p>事项标题</p>
      </div>
      <div class="apply applyWid" v-for="(item, index) in tableData" :key="index" @click="chankan()"
        :class="index % 2 != 0 ? 'active' : ''">
        <p>{{ item.date }}</p>
        <p>{{ item.name }}</p>
        <p>
          <a :title="item.province">{{ item.province }}</a>
        </p>
      </div>
    </el-dialog>
    <!-- 研判预警 -->
    <el-dialog :visible.sync="tcypyjp" :custom-class="customDialogClass" class="iuj">
      <div class="wgyq">研判预警</div>
      <el-tabs v-model="activeNameu" style="width: 90%; margin: 0 auto">
        <el-tab-pane label="12345热线预警" name="first">
          <div class="apply applyHead">
            <p>姓名</p>
            <p>电话</p>
            <p>事项标题</p>
            <p>所属街道</p>
          </div>
          <div class="apply" v-for="(item, index) in tableDatasy1" :key="index" @click="chankan(item)"
            :class="index % 2 != 0 ? 'active' : ''">
            <p>{{ item.name }}</p>
            <p>{{ item.phone }}</p>
            <p>
              <a :title="item.overviewInfo">{{ item.overviewInfo }}</a>
            </p>
            <p>
              <a :title="item.problemAddress">{{ item.problemAddress }}</a>
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="微信消息抓取" name="second">
          <div class="apply applyHead">
            <p>姓名</p>
            <p>电话</p>
            <p>事项标题</p>
            <p>所属街道</p>
          </div>
          <div class="apply" v-for="(item, index) in tableDatasxx" :key="index" @click="chankan(item)"
            :class="index % 2 != 0 ? 'active' : ''">
            <p>{{ item.name }}</p>
            <p>{{ item.phone }}</p>
            <p>
              <a :title="item.overviewInfo">{{ item.overviewInfo }}</a>
            </p>
            <p>
              <a :title="item.problemAddress">{{ item.problemAddress }}</a>
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="重点人员上访" name="third">
          <div class="apply applyHead">
            <p>姓名</p>
            <p>电话</p>
            <p>事项标题</p>
            <p>所属街道</p>
          </div>
          <div class="apply" v-for="(item, index) in tableDatasuu" :key="index" @click="chankan(item)"
            :class="index % 2 != 0 ? 'active' : ''">
            <p>{{ item.name }}</p>
            <p>{{ item.phone }}</p>
            <p>
              <a :title="item.overviewInfo">{{ item.overviewInfo }}</a>
            </p>
            <p>
              <a :title="item.problemAddress">{{ item.problemAddress }}</a>
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="省市集体访预警" name="fourth">
          <div class="apply applyHead">
            <p>姓名</p>
            <p>电话</p>
            <p>事项标题</p>
            <p>所属街道</p>
          </div>
          <div class="apply" v-for="(item, index) in tableDatastt" :key="index" @click="chankan(item)"
            :class="index % 2 != 0 ? 'active' : ''">
            <p>{{ item.name }}</p>
            <p>{{ item.phone }}</p>
            <p>
              <a :title="item.overviewInfo">{{ item.overviewInfo }}</a>
            </p>
            <p>
              <a :title="item.problemAddress">{{ item.problemAddress }}</a>
            </p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { ToolboxComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import api from '../../../api/api.js';

echarts.use([
  ToolboxComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

export default {
  name: "petitionRight",
  data() {
    return {
      tableDatasy1: [],
      tableDatasxx: [],
      tableDatasuu: [],
      tableDatastt: [],
      tcypyjp: false,
      inspectorIndex: 1,
      inspector: ['劳动和社会保障', '住建 ', '土地经营承包', '水利水电'],
      tableData: [{
        date: '欧*茜',
        name: '193****2231',
        province:
          '山大南路20号小区10号楼前有一处井盖破损，已经有居民踩空摔伤，希望有关部门维修，以免发生不必要的危险。',
        city: '山东省济南市历城区山大路街道',
        // address: '上海市普陀区金沙江路 1518 弄',
        // zip: 200333
      },
      {
        date: '巩*',
        name: '178****5369',
        province:
          '现港沟街道办事处租赁潘庄村集体土地用做办公用地（办公大楼，建委宿舍，港沟派出所和教师公寓地块）原合同与2019年12月31日到期，现一直使用上述地块，后续合同未签署，租金未支付。请求责令港沟街道办事处依照市场价格签署租赁合同并足额支付租赁费用，或者返还土地并支付2020年01月01日至返还土地时的土地使用费。',
        city: '山东省济南市历城区港沟街道潘庄村民委员会',
        // address: '上海市普陀区金沙江路 1518 弄',
        // zip: 200333
      },
      {
        date: '奥*',
        name: '178****5230',
        province:
          '购买的康窝口呼吸矫正贴儿童闭嘴神器，该产品在商品标题上声称有“矫正”的功能，没有事实依据，该产品是日用品，不属于医疗器械，商家利用科学无定论的日用品明示或暗示其所销售的日用品除了是生活必需品还具有矫正作用，以日用品冒充医疗器械，其目的是抬高商品声誉，不正当的获得了竞争优势，对不特定的同业竞争者的合法权益产生损害，希望商家退户退款并赔偿。',
        city: '河北省石家庄市长安区建北街道',
        // address: '上海市普陀区金沙江路 1518 弄',
        // zip: 200333
      },
      {
        date: '京*',
        name: '193****1452',
        province:
          '本人在抖音商城 网店：素美堂个体店（历城区由乘百货商行），购买了一款（GINBI芦荟去角质啫喱）的化妆品，这个商家夸大虚构化妆品的功效，误导消费者购买，根据消费者权益保护法的规定，我有权利向商家进行退货退款，并要求退一赔三，赔偿金额不足500元为500元。',
        city: '山东省济南市市中区大观园街道',
        // address: '上海市普陀区金沙江路 1518 弄',
        // zip: 200333
      },
      {
        date: '张*闪',
        name: '178****2234',
        province:
          '购买的特伦苏高蛋白有机纯牛奶收到货看了一下商品的营养成分，然后我在网上看文章的时候看到宣传高蛋白的标准然后对比了一下这个这款牛奶看了一下商品的蛋白质含量达不到宣传高蛋白的标准，商品达不到高蛋白标准宣传高蛋白。我认为商家夸大虚假宣传牛奶商品属于欺诈行为，消费者有权利按照消费者权益保护法第55条申请退一赔三不足500为500的诉求如协商无果，希望市场监管同志为我维权。',
        city: '山东省济南市历城区山大路街道',
        // address: '上海市普陀区金沙江路 1518 弄',
        // zip: 200333
      },
      {
        date: '李*效',
        name: '158****564',
        province:
          '本人在济南泉新食代餐饮管理有限公司（历城区山大路136号三楼北侧A区）的美团外卖“沙县小吃”购买的瓦罐猪肚汤，宣传该产品具有“养胃”等保健功效，普通食品广告宣传保健功能，是对其性能和功能的虚假宣传，容易引起消费者误解，误导消费者购买，此虚假宣传破坏市场公平竞争环境，同时也容易对消费者构成欺诈误导。要求退一赔三，赔偿金额不足500元为500元',
        city: '山东省济南市历城区山大路街道',
        // address: '上海市普陀区金沙江路 1518 弄',
        // zip: 200333
      }
      ],
      customDialogClass: 'dialog-bg-red', // 自定义弹窗的样式类名
      unsettledShow: false,
      // 重点人员数量配置
      keynoteNumberChart: null,
      keynoteNumberOption: null,
      // 各街道未办结事件数量配置
      notDoneNumberChart: null,
      notDoneNumberOption: null,
      // 各街道信访事件来源信息配置
      sourceInfoChart: null,
      sourceInfoOption: null
    }
  },
  created() {
    this.$nextTick(() => {
      this.initKeynoteNumber();
      this.initNotDoneNumber();
      this.initSourceInfo();
    })
  },
  methods: {
    tcypyj() {
      this.activeNameu = 'first';
      this.tcypyjp = true;
      api
        .serviceVisitsLargeList({
          pageNum: 5,
          pageSize: 8,
        })
        .then((res) => {
          this.tableDatasy1 = res.data.rows;
        });
      api
        .serviceVisitsLargeList({
          pageNum: 6,
          pageSize: 8,
        })
        .then((res) => {
          this.tableDatasxx = res.data.rows;
        });
      api
        .serviceVisitsLargeList({
          pageNum: 7,
          pageSize: 8,
        })
        .then((res) => {
          this.tableDatasuu = res.data.rows;
        });
      api
        .serviceVisitsLargeList({
          pageNum: 8,
          pageSize: 8,
        })
        .then((res) => {
          this.tableDatastt = res.data.rows;
        });
    },
    inspectorClick(index) {
      this.inspectorIndex = index
      // 唐冶街道
      if (index == 1) {
        this.tableData = [{
          date: '欧*茜',
          name: '193****2231',
          province:
            '山大南路20号小区10号楼前有一处井盖破损，已经有居民踩空摔伤，希望有关部门维修，以免发生不必要的危险。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '巩*',
          name: '178****5369',
          province:
            '现港沟街道办事处租赁潘庄村集体土地用做办公用地（办公大楼，建委宿舍，港沟派出所和教师公寓地块）原合同与2019年12月31日到期，现一直使用上述地块，后续合同未签署，租金未支付。请求责令港沟街道办事处依照市场价格签署租赁合同并足额支付租赁费用，或者返还土地并支付2020年01月01日至返还土地时的土地使用费。',
          city: '山东省济南市历城区港沟街道潘庄村民委员会',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '奥*',
          name: '178****5230',
          province:
            '购买的康窝口呼吸矫正贴儿童闭嘴神器，该产品在商品标题上声称有“矫正”的功能，没有事实依据，该产品是日用品，不属于医疗器械，商家利用科学无定论的日用品明示或暗示其所销售的日用品除了是生活必需品还具有矫正作用，以日用品冒充医疗器械，其目的是抬高商品声誉，不正当的获得了竞争优势，对不特定的同业竞争者的合法权益产生损害，希望商家退户退款并赔偿。',
          city: '河北省石家庄市长安区建北街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '京*',
          name: '193****1452',
          province:
            '本人在抖音商城 网店：素美堂个体店（历城区由乘百货商行），购买了一款（GINBI芦荟去角质啫喱）的化妆品，这个商家夸大虚构化妆品的功效，误导消费者购买，根据消费者权益保护法的规定，我有权利向商家进行退货退款，并要求退一赔三，赔偿金额不足500元为500元。',
          city: '山东省济南市市中区大观园街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '张*闪',
          name: '178****2234',
          province:
            '购买的特伦苏高蛋白有机纯牛奶收到货看了一下商品的营养成分，然后我在网上看文章的时候看到宣传高蛋白的标准然后对比了一下这个这款牛奶看了一下商品的蛋白质含量达不到宣传高蛋白的标准，商品达不到高蛋白标准宣传高蛋白。我认为商家夸大虚假宣传牛奶商品属于欺诈行为，消费者有权利按照消费者权益保护法第55条申请退一赔三不足500为500的诉求如协商无果，希望市场监管同志为我维权。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '李*效',
          name: '158****564',
          province:
            '本人在济南泉新食代餐饮管理有限公司（历城区山大路136号三楼北侧A区）的美团外卖“沙县小吃”购买的瓦罐猪肚汤，宣传该产品具有“养胃”等保健功效，普通食品广告宣传保健功能，是对其性能和功能的虚假宣传，容易引起消费者误解，误导消费者购买，此虚假宣传破坏市场公平竞争环境，同时也容易对消费者构成欺诈误导。要求退一赔三，赔偿金额不足500元为500元',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        }
        ]
      } else if (index == 2) {
        //山大路街道
        this.tableData = [{
          date: '张*闪',
          name: '178****2234',
          province:
            '购买的特伦苏高蛋白有机纯牛奶收到货看了一下商品的营养成分，然后我在网上看文章的时候看到宣传高蛋白的标准然后对比了一下这个这款牛奶看了一下商品的蛋白质含量达不到宣传高蛋白的标准，商品达不到高蛋白标准宣传高蛋白。我认为商家夸大虚假宣传牛奶商品属于欺诈行为，消费者有权利按照消费者权益保护法第55条申请退一赔三不足500为500的诉求如协商无果，希望市场监管同志为我维权。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        }, {
          date: '欧*茜',
          name: '193****2231',
          province:
            '山大南路20号小区10号楼前有一处井盖破损，已经有居民踩空摔伤，希望有关部门维修，以免发生不必要的危险。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '巩*',
          name: '178****5369',
          province:
            '现港沟街道办事处租赁潘庄村集体土地用做办公用地（办公大楼，建委宿舍，港沟派出所和教师公寓地块）原合同与2019年12月31日到期，现一直使用上述地块，后续合同未签署，租金未支付。请求责令港沟街道办事处依照市场价格签署租赁合同并足额支付租赁费用，或者返还土地并支付2020年01月01日至返还土地时的土地使用费。',
          city: '山东省济南市历城区港沟街道潘庄村民委员会',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '奥*',
          name: '178****5230',
          province:
            '购买的康窝口呼吸矫正贴儿童闭嘴神器，该产品在商品标题上声称有“矫正”的功能，没有事实依据，该产品是日用品，不属于医疗器械，商家利用科学无定论的日用品明示或暗示其所销售的日用品除了是生活必需品还具有矫正作用，以日用品冒充医疗器械，其目的是抬高商品声誉，不正当的获得了竞争优势，对不特定的同业竞争者的合法权益产生损害，希望商家退户退款并赔偿。',
          city: '河北省石家庄市长安区建北街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '京*',
          name: '193****1452',
          province:
            '本人在抖音商城 网店：素美堂个体店（历城区由乘百货商行），购买了一款（GINBI芦荟去角质啫喱）的化妆品，这个商家夸大虚构化妆品的功效，误导消费者购买，根据消费者权益保护法的规定，我有权利向商家进行退货退款，并要求退一赔三，赔偿金额不足500元为500元。',
          city: '山东省济南市市中区大观园街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },

        {
          date: '李*效',
          name: '158****564',
          province:
            '本人在济南泉新食代餐饮管理有限公司（历城区山大路136号三楼北侧A区）的美团外卖“沙县小吃”购买的瓦罐猪肚汤，宣传该产品具有“养胃”等保健功效，普通食品广告宣传保健功能，是对其性能和功能的虚假宣传，容易引起消费者误解，误导消费者购买，此虚假宣传破坏市场公平竞争环境，同时也容易对消费者构成欺诈误导。要求退一赔三，赔偿金额不足500元为500元',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        }
        ]
      } else if (index == 3) {
        //港沟街道
        this.tableData = [{
          date: '京*',
          name: '193****1452',
          province:
            '本人在抖音商城 网店：素美堂个体店（历城区由乘百货商行），购买了一款（GINBI芦荟去角质啫喱）的化妆品，这个商家夸大虚构化妆品的功效，误导消费者购买，根据消费者权益保护法的规定，我有权利向商家进行退货退款，并要求退一赔三，赔偿金额不足500元为500元。',
          city: '山东省济南市市中区大观园街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '巩*',
          name: '178****5369',
          province:
            '现港沟街道办事处租赁潘庄村集体土地用做办公用地（办公大楼，建委宿舍，港沟派出所和教师公寓地块）原合同与2019年12月31日到期，现一直使用上述地块，后续合同未签署，租金未支付。请求责令港沟街道办事处依照市场价格签署租赁合同并足额支付租赁费用，或者返还土地并支付2020年01月01日至返还土地时的土地使用费。',
          city: '山东省济南市历城区港沟街道潘庄村民委员会',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        }, {
          date: '欧*茜',
          name: '193****2231',
          province:
            '山大南路20号小区10号楼前有一处井盖破损，已经有居民踩空摔伤，希望有关部门维修，以免发生不必要的危险。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '奥*',
          name: '178****5230',
          province:
            '购买的康窝口呼吸矫正贴儿童闭嘴神器，该产品在商品标题上声称有“矫正”的功能，没有事实依据，该产品是日用品，不属于医疗器械，商家利用科学无定论的日用品明示或暗示其所销售的日用品除了是生活必需品还具有矫正作用，以日用品冒充医疗器械，其目的是抬高商品声誉，不正当的获得了竞争优势，对不特定的同业竞争者的合法权益产生损害，希望商家退户退款并赔偿。',
          city: '河北省石家庄市长安区建北街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },

        {
          date: '张*闪',
          name: '178****2234',
          province:
            '购买的特伦苏高蛋白有机纯牛奶收到货看了一下商品的营养成分，然后我在网上看文章的时候看到宣传高蛋白的标准然后对比了一下这个这款牛奶看了一下商品的蛋白质含量达不到宣传高蛋白的标准，商品达不到高蛋白标准宣传高蛋白。我认为商家夸大虚假宣传牛奶商品属于欺诈行为，消费者有权利按照消费者权益保护法第55条申请退一赔三不足500为500的诉求如协商无果，希望市场监管同志为我维权。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '李*效',
          name: '158****564',
          province:
            '本人在济南泉新食代餐饮管理有限公司（历城区山大路136号三楼北侧A区）的美团外卖“沙县小吃”购买的瓦罐猪肚汤，宣传该产品具有“养胃”等保健功效，普通食品广告宣传保健功能，是对其性能和功能的虚假宣传，容易引起消费者误解，误导消费者购买，此虚假宣传破坏市场公平竞争环境，同时也容易对消费者构成欺诈误导。要求退一赔三，赔偿金额不足500元为500元',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        }
        ]
      } else if (index == 4) {
        //华山街道
        this.tableData = [{
          date: '巩*',
          name: '178****5369',
          province:
            '现港沟街道办事处租赁潘庄村集体土地用做办公用地（办公大楼，建委宿舍，港沟派出所和教师公寓地块）原合同与2019年12月31日到期，现一直使用上述地块，后续合同未签署，租金未支付。请求责令港沟街道办事处依照市场价格签署租赁合同并足额支付租赁费用，或者返还土地并支付2020年01月01日至返还土地时的土地使用费。',
          city: '山东省济南市历城区港沟街道潘庄村民委员会',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        }, {
          date: '欧*茜',
          name: '193****2231',
          province:
            '山大南路20号小区10号楼前有一处井盖破损，已经有居民踩空摔伤，希望有关部门维修，以免发生不必要的危险。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '奥*',
          name: '178****5230',
          province:
            '购买的康窝口呼吸矫正贴儿童闭嘴神器，该产品在商品标题上声称有“矫正”的功能，没有事实依据，该产品是日用品，不属于医疗器械，商家利用科学无定论的日用品明示或暗示其所销售的日用品除了是生活必需品还具有矫正作用，以日用品冒充医疗器械，其目的是抬高商品声誉，不正当的获得了竞争优势，对不特定的同业竞争者的合法权益产生损害，希望商家退户退款并赔偿。',
          city: '河北省石家庄市长安区建北街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '李*效',
          name: '158****564',
          province:
            '本人在济南泉新食代餐饮管理有限公司（历城区山大路136号三楼北侧A区）的美团外卖“沙县小吃”购买的瓦罐猪肚汤，宣传该产品具有“养胃”等保健功效，普通食品广告宣传保健功能，是对其性能和功能的虚假宣传，容易引起消费者误解，误导消费者购买，此虚假宣传破坏市场公平竞争环境，同时也容易对消费者构成欺诈误导。要求退一赔三，赔偿金额不足500元为500元',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '京*',
          name: '193****1452',
          province:
            '本人在抖音商城 网店：素美堂个体店（历城区由乘百货商行），购买了一款（GINBI芦荟去角质啫喱）的化妆品，这个商家夸大虚构化妆品的功效，误导消费者购买，根据消费者权益保护法的规定，我有权利向商家进行退货退款，并要求退一赔三，赔偿金额不足500元为500元。',
          city: '山东省济南市市中区大观园街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        },
        {
          date: '张*闪',
          name: '178****2234',
          province:
            '购买的特伦苏高蛋白有机纯牛奶收到货看了一下商品的营养成分，然后我在网上看文章的时候看到宣传高蛋白的标准然后对比了一下这个这款牛奶看了一下商品的蛋白质含量达不到宣传高蛋白的标准，商品达不到高蛋白标准宣传高蛋白。我认为商家夸大虚假宣传牛奶商品属于欺诈行为，消费者有权利按照消费者权益保护法第55条申请退一赔三不足500为500的诉求如协商无果，希望市场监管同志为我维权。',
          city: '山东省济南市历城区山大路街道',
          // address: '上海市普陀区金沙江路 1518 弄',
          // zip: 200333
        }
        ]
      }
    },
    unsettled() {
      this.unsettledShow = true
    },
    // 重点人员数量柱状图
    initKeynoteNumber() {
      let chartDom = document.getElementById('keynoteNumberCom');
      this.keynoteNumberChart = echarts.init(chartDom);
      this.keynoteNumberOption = {
        grid: {
          top: '35',
          left: '60',
          right: '20',
          bottom: '40',
        },
        tooltip: {
          trigger: 'axis',
          borderColor: '#3B708F', // 设置 tooltip 边线颜色
          backgroundColor: 'rgba(0,44,58, 0.9)', // 设置 tooltip 的背景颜色
          textStyle: {
            color: '#B1D6F1', // 设置 tooltip 文本的颜色
          },
        },
        dataZoom: [
          {
            type: 'slider', // 使用滑动条
            show: true, // 显示滚动条
            start: 0, // 默认起始位置为0，表示从最开始显示数据
            end: 30, // 默认结束位置为20，表示一开始显示20%的数据
            filterMode: 'empty', // 设置滚动条不显示数据间隔较少的项
            height: 8, // 设置滚动条的高度
            handleIcon: 'path://M0,0 L10,0 L5,10 L0,0', // 自定义滚动条手柄的图标
            handleSize: '120%', // 设置滚动条手柄的尺寸
            handleStyle: {
              color: '#195761', // 设置滚动条手柄的颜色
            },
            backgroundColor: '#1B333E', // 设置滚动条背景颜色
            borderColor: '#195761', // 设置滚动条边框颜色
            bottom: 10,
            textStyle: {
              color: '#9AB7CD', // 设置滚动条上方字体的颜色
            },
          },
          {
            type: 'inside', // 内置滚动条
          },
        ],
        xAxis: [
          {
            type: 'category',
            data: ['全福街道', '华山街道', '荷花街道', '鲍山街道', '唐冶街道', '彩石街道', '唐王街道','董家街道','港沟街道','山大路街道','洪家楼街道','东风街道','王舍人街道','郭店街道'],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              textStyle: {
                color: '#9db2bc',
                fontSize: 12
              }
            },
            nameTextStyle: {
              color: '#9AB7CD', // 设置 y 轴名称的颜色
            },
          }
        ],
        yAxis: [
          {
            name: '人',
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#9db2bc',
                fontSize: 12
              }
            },
            nameTextStyle: {
              color: '#9AB7CD', // 设置 y 轴名称的颜色
            },
          }
        ],
        series: [
          {
            name: '人数',
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              //通常情况下：
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(0,98,255, 1)' },
                  { offset: 1, color: 'rgba(0,98,255, 0.1)' },
                ]),
                barCategoryGap: 0, // 设置不同系列的柱子之间的间距为0
                borderRadius: [5, 5, 0, 0], // 设置圆角，顺序为左上、右上、右下、左下
                barBorderRadius: 8,
                //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                // color: function (params) {
                //     var colorList = [
                //         ['rgb(14,102,179)', 'rgba(255,112,0, 1)'],
                //         ['#F5cF0D', '#9AB7CD'],
                //         ['#61dbe8', '#0785de'],
                //         ['#ff9717', '#ff4518'],
                //     ];
                //     var index = params.dataIndex;
                //     if (params.dataIndex >= colorList.length) {
                //         index = params.dataIndex - colorList.length;
                //     }
                //     return new echarts.graphic.LinearGradient(0, 0, 1, 0,
                //         [{
                //             offset: 0,
                //             color: colorList[index][0]
                //         },
                //             {
                //                 offset: 1,
                //                 color: colorList[index][1]
                //             }
                //         ]);
                // }
              },
            },
            data: [8, 63, 26, 36, 5, 0, 1,15,13,10,2,5,37,2]
          }
        ]
      };
      this.keynoteNumberOption && this.keynoteNumberChart.setOption(this.keynoteNumberOption);
    },
    // 各街道未办结事件数量饼图
    initNotDoneNumber() {
      let data = [
        { value: 26, count: 60, name: '港沟街道' },
        { value: 20, count: 20, name: '山大路街道' },
        { value: 0, count: 9, name: '洪家楼街道' },
        { value: 107, count: 6, name: '东风街道' },
        { value: 3, count: 5, name: '荷花路街道' },
        { value: 85, count: 9, name: '鲍山街道' },
        { value: 33, count: 6, name: '唐冶街道' },
        { value: 68, count: 5, name: '王舍人街道' },
        { value: 30, count: 9, name: '全福街道' },
        { value: 10, count: 6, name: '华山街道' },
        { value: 25, count: 5, name: '唐王街道' },
        { value: 40, count: 9, name: '郭店街道' },
        { value: 23, count: 6, name: '彩石街道' },
        { value: 40, count: 5, name: '董家街道' },
      ]
      let chartDom = document.getElementById('notDoneNumberCom');
      this.notDoneNumberChart = echarts.init(chartDom);
      this.notDoneNumberOption = {
        color: ['#3a90f5', '#885dea', '#f4963e', '#8ffa9a', '#99ed7d'],
        grid: {
          left: '0%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',//设置图例的方向
          left: '55%',
          top: "center",
          itemGap: 10,//设置图例的间距
          icon: "circle",// 图例项的icon,类型包括 circle(圆形),rect(正方形),
          formatter: (e) => {
            let radio = 0
            //通过e的内容,获取后台的百分比和车数
            data.forEach(item => {
              if (item.name == e) {
                radio = item.count
              }
            });
            let arr = [e]
            //此处的a,b,c是textStyle里面配置的a,b,c,可以自定义
            return arr.join(' ')
          },
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '未半事件数量',
            type: 'pie',
            center: ['30%', '50%'],
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#0E2F3C',
              borderWidth: 3
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      };

      this.notDoneNumberOption && this.notDoneNumberChart.setOption(this.notDoneNumberOption);
    },
    // 各街道信访事件来源信息
    initSourceInfo() {
      let data = [
      { value: 460, count: 60, name: '港沟街道' },
        { value: 146, count: 20, name: '山大路街道' },
        { value: 321, count: 9, name: '洪家楼街道' },
        { value: 810, count: 6, name: '东风街道' },
        { value: 123, count: 5, name: '荷花路街道' },
        { value: 716, count: 9, name: '鲍山街道' },
        { value: 181, count: 6, name: '唐冶街道' },
        { value: 1522, count: 5, name: '王舍人街道' },

        { value: 1033, count: 9, name: '全福街道' },
        { value: 608, count: 6, name: '华山街道' },
        { value: 468, count: 5, name: '唐王街道' },
        { value: 328, count: 9, name: '郭店街道' },
        { value: 247, count: 6, name: '彩石街道' },
        { value: 239, count: 5, name: '董家街道' },
      ]
      let chartDom = document.getElementById('sourceInfoCom');
      this.sourceInfoChart = echarts.init(chartDom);
      this.sourceInfoOption = {
        grid: {
          left: '1%',
          bottom: '3%',
          top: '1%',
          containLabel: true
        },
        legend: {
          orient: 'vertical',//设置图例的方向
          left: '55%',
          top: "center",
          itemGap: 10,//设置图例的间距
          icon: "circle",// 图例项的icon,类型包括 circle(圆形),rect(正方形),
          formatter: (e) => {
            let radio = 0
            //通过e的内容,获取后台的百分比和车数
            data.forEach(item => {
              if (item.name == e) {
                radio = item.count
              }
            });
            let arr = [e]
            //此处的a,b,c是textStyle里面配置的a,b,c,可以自定义
            return arr.join(' ')
          },
          textStyle: {
            color: '#fff'
          }
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        color: ['#3a90f5', '#885dea', '#f4963e', '#8ffa9a', '#99ed7d'],
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            center: ['30%', '50%'],
            radius: ['20%', '90%'],
            roseType: 'area',
            avoidLabelOverlap: false,
            itemStyle: {
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      };
      this.sourceInfoOption && this.sourceInfoChart.setOption(this.sourceInfoOption);
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-bg-red {
  background: rgba(0, 31, 47, 0.7) url(../../../assets/bg.png) no-repeat !important;
  background-size: 100% 100% !important;
  box-shadow: none !important;
  height: 70% !important;
  z-index: 999999 !important;
}

.petitionRight {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  height: calc(100% - 20px);

  //background: rgba(40,110, 123, 0.2);
  //background: rgba(28,111, 126, 0.8);
  //  各街道重点人员数量
  .keynoteNumber,
  .notDoneNumber,
  .sourceInfo {
    width: 100%;
    height: 32%;
    background: rgba(28, 111, 126, 0.3);
  }

  #keynoteNumberCom,
  #notDoneNumberCom,
  #sourceInfoCom {
    width: 100%;
    height: calc(100% - 40px);
  }
}
</style>
