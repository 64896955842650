<template>
  <!-- 右侧内容 ---------- start --------- -->
  <div class="main-box-rig-cont">
    <div class="title">
      <h1>调度月历</h1>
    </div>
    <!-- <div class="main-box-rig-top">
      <div class="main-box-rig-top-item">
        <p>预警次数</p>
        <span>37</span>
      </div>
      <div class="main-box-rig-top-item">
        <p>日均次数</p>
        <span>6</span>
      </div>
      <div class="main-box-rig-top-item">
        <p>调度次数</p>
        <span>35</span>
      </div>
      <div class="main-box-rig-top-item">
        <p>日均次数</p>
        <span>5</span>
      </div>
    </div> -->
    <div class="main-box-rig-time">
      <el-date-picker v-model="value1" type="month" placeholder="选择月">
      </el-date-picker>
      <div class="main-box-rig-time-date">
        <div :class="['date' + item, 'date']" v-for="item in 30" :key="'a' + item">
          {{ item }}
        </div>
        <div style="width:30px;height:0px;margin:5px;" v-for="item in 10" :key="'b' + item"></div>
      </div>
    </div>
    <!-- 领导专版 -->
    <div class="main-box-rig-leader">
      <div class="title">
        <h1>领导专班&nbsp; {{ speciallyPerson }}</h1>
      </div>
      <div class="main-box-rig-top">
        <div class="main-box-rig-top-item">
          <p>工作专班成员</p>
          <span>{{ speciallyObj.workTeamMember || speciallyObj.workSpecialClass || '暂无' }}</span>
        </div>
        <div class="main-box-rig-top-item">
          <p>专班负责人</p>
          <span>{{ speciallyObj.specialShiftLeader || speciallyObj.packageLeader || '暂无' }}</span>
        </div>
        <div class="main-box-rig-top-item">
          <p>责任单位</p>
          <span>{{ speciallyObj.responsibilityOrg || '暂无' }}</span>
        </div>
        <!--                <div class="main-box-rig-top-item">-->
        <!--                    <p>调度评分</p>-->
        <!--                    <span>8</span>-->
        <!--                </div>-->
        <!--                <div class="main-box-rig-top-item">-->
        <!--                    <p>疏散客流</p>-->
        <!--                    <span>3951</span>-->
        <!--                </div>-->
        <!--                <div class="main-box-rig-top-item">-->
        <!--                    <p>调度车次</p>-->
        <!--                    <span>640</span>-->
        <!--                </div>-->
      </div>
    </div>
    <!-- 调度趋势统计-->
    <div class="main-box-rig-trend">
      <div class="title">
        <h1>调度趋势统计</h1>
      </div>
      <div class="bgTitle">
        <dv-decoration-11 @click.native="changeActive(1)" class="bg" :class="active == 1 ? 'active' : ''">
          重点人员
        </dv-decoration-11>
        <dv-decoration-11 @click.native="changeActive(2)" class="bg"
          :class="active == 2 ? 'active' : ''">重点人群</dv-decoration-11>
      </div>
      <div id="trendCom"></div>
    </div>
  </div>
  <!-- 右侧内容 ---------- end ----------- -->
</template>

<script>
import * as echarts from "echarts";
import api from "@/api/api";

export default {
  name: "aboutRight",
  data() {
    return {
      value1: '',
      active: 1,
      data: [
        { value: 1048, count: 60, name: '山大路街道' },
        { value: 735, count: 20, name: '洪家楼街道' },
        { value: 580, count: 9, name: '东风街道' },
        { value: 484, count: 6, name: '全福街道' },
        { value: 300, count: 5, name: '华山街道' },
        { value: 1048, count: 60, name: '荷花街道' },
        { value: 735, count: 20, name: '王舍人街道' },
        { value: 580, count: 9, name: '鲍山街道' },
        { value: 484, count: 6, name: '港沟街道' },
        { value: 300, count: 5, name: '唐冶街道' },
        { value: 1048, count: 60, name: '郭店街道' },
        { value: 735, count: 20, name: '彩石街道' },
        { value: 580, count: 9, name: '董家街道' },
        { value: 484, count: 6, name: '唐王街道' }
      ],
      // 领导专班
      speciallyPerson: '',
      // 专班数据
      speciallyObj: {}
    }
  },
  created() {
    this.$nextTick(() => {
      this.initTrendCom();
    })
    // 获取专班负责人
    this.$bus.$on('specially', (val, flag) => {
      if (flag) {
        this.speciallyObj = val
        this.speciallyPerson = val.specialShiftLeader.slice(0, 3)
      } else {
        this.speciallyObj = val
        this.speciallyPerson = val.packageLeader.slice(0, 3)
      }
    })
  },
  methods: {
    changeActive(val) {
      this.active = val
      if (val == 1) {
        this.eventCategoryOption.series[0].data = [
          { value: 1048, count: 60, name: '山大路街道' },
          { value: 735, count: 20, name: '洪家楼街道' },
          { value: 580, count: 9, name: '东风街道' },
          { value: 484, count: 6, name: '全福街道' },
          { value: 300, count: 5, name: '华山街道' },
          { value: 1048, count: 60, name: '荷花街道' },
          { value: 735, count: 20, name: '王舍人街道' },
          { value: 580, count: 9, name: '鲍山街道' },
          { value: 484, count: 6, name: '港沟街道' },
          { value: 300, count: 5, name: '唐冶街道' },
          { value: 1048, count: 60, name: '郭店街道' },
          { value: 735, count: 20, name: '彩石街道' },
          { value: 580, count: 9, name: '董家街道' },
          { value: 484, count: 6, name: '唐王街道' }
        ]
        this.eventCategoryChart.setOption(this.eventCategoryOption);
      } else {
        this.eventCategoryOption.series[0].data = [
          { value: 627, count: 60, name: '山大路街道' },
          { value: 245, count: 20, name: '洪家楼街道' },
          { value: 255, count: 9, name: '东风街道' },
          { value: 567, count: 6, name: '全福街道' },
          { value: 245, count: 5, name: '华山街道' },
          { value: 68, count: 60, name: '荷花街道' },
          { value: 244, count: 20, name: '王舍人街道' },
          { value: 580, count: 9, name: '鲍山街道' },
          { value: 245, count: 6, name: '港沟街道' },
          { value: 300, count: 5, name: '唐冶街道' },
          { value: 563, count: 60, name: '郭店街道' },
          { value: 244, count: 20, name: '彩石街道' },
          { value: 580, count: 9, name: '董家街道' },
          { value: 352, count: 6, name: '唐王街道' }
        ]
        this.eventCategoryOption.legend.formatter();
        this.eventCategoryChart.setOption(this.eventCategoryOption);
      }
    },
    // 调度趋势统计折线图
    initTrendCom() {
      let chartDom = document.getElementById('trendCom');
      this.eventCategoryChart = echarts.init(chartDom);
      this.eventCategoryOption = {
        color: ['#3a90f5', '#885dea', '#f4963e', '#8ffa9a', '#99ed7d'],
        grid: {
          left: '0%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',//设置图例的方向
          left: '52%',
          top: "center",
          itemGap: 15,//设置图例的间距
          icon: "circle",// 图例项的icon,类型包括 circle(圆形),rect(正方形),
          formatter: (e) => {
            let radio = 0
            //通过e的内容,获取后台的百分比和车数
            this.data.forEach(item => {
              if (item.name == e) {
                radio = item.value
                // radio = item.count
              }
            });
            let arr = [e]
            //此处的a,b,c是textStyle里面配置的a,b,c,可以自定义
            return arr.join(' ')
          },
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '未半事件数量',
            type: 'pie',
            // center:['50%','50%'],
            center: ['25%', '50%'],
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#0E2F3C',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 30,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.data
          }
        ]
      };
      this.eventCategoryOption && this.eventCategoryChart.setOption(this.eventCategoryOption);
    },
    // getData() {
    //     console.log(this.rollQuery)
    //     api
    //         .getData({
    //             pageNum:1,
    //             pageSize:10
    //         })
    //         .then((res) => {
    //             this.rollCallList = res.data.rows;
    //             console.log(this.rollCallList)
    //         });
    // },
    // // 重点群体名单
    // Keygroups() {
    //     api
    //         .Keygroups({
    //             pageNum:1,
    //             pageSize:10
    //         })
    //         .then((res) => {
    //             this.keyGroupsList = res.data.rows;
    //             console.log(this.keyGroupsList)
    //         });
    // },
  },
}
</script>

<style lang="scss" scoped>
.main-box-rig-cont {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 22%;
  height: calc(100% - 20px);
  /*height: 100%;*/
}

.main-box-rig-cont .main-box-rig-top {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  //flex-wrap: wrap;
  background-color: rgba(0, 44, 58, 0.9);
  border: 1px solid #3B708F;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 13px;
  min-height: 160px;

  .main-box-rig-top-item {
    //overflow: hidden;
    //text-overflow: ellipsis;
    // white-space: nowrap;
    width: 100%;
    padding: 5px 12px;
    box-sizing: border-box;
    color: #B1D6F1;
    display: flex;
    justify-content: space-between;

    //align-items: center;
    p {
      width: 80px;
    }

    span {
      display: block;
      width: calc(100% - 80px);
      //overflow: hidden;
      //text-overflow:ellipsis;
      //white-space: nowrap;
      color: #00DEF0;
      margin-left: 6px;
    }
  }
}

/*领导专班，调度趋势统计*/
.main-box-rig-leader,
.main-box-rig-trend {
  margin-top: 10px;
}

.main-box-rig-trend {
  background: rgba(28, 111, 126, 0.4);
  height: 40%;
}

#trendCom {
  height: calc(100% - 65px);
}

//月份日期选择器
.main-box-rig-time {
  background-color: rgba(0, 44, 58, 0.9);
  padding-top: 10px;

  &-date {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    color: #fff;
    padding: 10px 5px;
    text-align: center;
    line-height: 25px;
    box-sizing: border-box;

    .date {
      font-size: 13px;
      cursor: pointer;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      background-color: #405568;
      margin: 10px 5px;
    }

    .date20,
    .date5,
    .date25,
    .date12 {
      background-color: #c99a2e;
    }
  }
}

.el-input__inner,
.el-textarea__inner {
  border: 1px solid #286e7b !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

//饼图tab
.bgTitle {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;

  .bg {
    cursor: pointer;
    width: 120px;
    height: 40px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 13px;
  }

  .active {
    width: 120px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    //color:#f29701;
    color: #00def0;
    font-size: 13px;

  }
}
</style>
