<template>
  <div id="app">
    <routerView></routerView>
    <!-- <index /> -->
  </div>
</template>

<script>
import index from './components/index.vue';

export default {
  name: 'App',
  components: {
    index,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.v-modal{
  display: none !important;
}
html,body,#app{
    width:100%;
    height:100%;
    min-height:100%;
}
a {
  text-decoration: none;
}
/* 修改滚动条的宽度和颜色 */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #286e7b;
  border-radius: 20px;
}

/* 鼠标悬停时滚动条 thumb 的颜色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #15555f;
}
.BMap_cpyCtrl {
    display: none;
}
.anchorBL {
    display: none
}

/* 修改滚动条轨道的颜色 */
::-webkit-scrollbar-track {
  background-color: #1b3945;
  border-radius: 20px;
}
</style>
