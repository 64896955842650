import { render, staticRenderFns } from "./dataCount.vue?vue&type=template&id=615828fa"
import script from "./dataCount.vue?vue&type=script&lang=js"
export * from "./dataCount.vue?vue&type=script&lang=js"
import style0 from "./dataCount.vue?vue&type=style&index=0&id=615828fa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports