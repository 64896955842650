<template>
  <div id="mapCom"></div>
</template>

<script>
import * as echarts from 'echarts/core';
import { TitleComponent, TooltipComponent } from 'echarts/components';
import { ScatterChart, EffectScatterChart, CustomChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/extension/bmap/bmap';

echarts.use([
  TitleComponent,
  TooltipComponent,
  ScatterChart,
  EffectScatterChart,
  CustomChart,
  CanvasRenderer,
  UniversalTransition
]);
import styleJson from './styleJson'


export default {
  data() {
    return {
      // 地图实例
      mapChart: null,
      // 地图配置
      mapOption: null
    }
  },
  created() {
    this.$nextTick(() => {
      // 正式key lTsBFgRDtiyBpVljvBYkrXNju8v2MoX1
      this.loadBMap('CfGlypDwTelWikV9cxqCG6lG5dkElpt1').then(() => {
        this.initMap()
      }).catch(() => {
      })


    })
  },

  methods: {
    loadBMap(ak) {
      return new Promise(function (resolve, reject) {
        if (typeof BMap !== 'undefined') {
          resolve(BMap)
          return true
        }
        window.onBMapCallback = function () {
          resolve(BMap)
        }
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://api.map.baidu.com/api?v=3.0&ak=' + ak + '&callback=onBMapCallback'
        // script.src = 'https://api.map.baidu.com/api?v=2.0&ak=' + ak + '&__ec_v__=20190126&callback=onBMapCallback'
        script.onerror = reject
        document.head.appendChild(script)
      })
    },
    initMap() {
      let chartDom = document.getElementById('mapCom');
      this.mapChart = echarts.init(chartDom);


      let data = [
        { name: '山大路街道', number: '20%', number1: '30%', number2: '25%', number3: '15%', number4: '10%', value: '200', time: '9:00 - 10:00', },
        { name: '洪家楼街道', number: '32%', number1: '20%', number2: '18%', number3: '10%', number4: '20%', value: '200', time: '9:00 - 10:00', },
        { name: '东风街道', number: '22%', number1: '8%', number2: '20%', number3: '20%', number4: '30%', value: '200', time: '9:00 - 10:00', },
        { name: '全福街道', number: '26%', number1: '20%', number2: '24%', number3: '22%', number4: '8%', value: '200', time: '9:00 - 10:00', },
        { name: '华山街道', number: '20%', number1: '30%', number2: '16%', number3: '16%', number4: '18%', value: '200', time: '9:00 - 10:00', },
        { name: '荷花街道', number: '16%', number1: '16%', number2: '20%', number3: '30%', number4: '18%', value: '200', time: '9:00 - 10:00' },
        { name: '王舍人街道', number: '16%', number1: '30%', number2: '16%', number3: '16%', number4: '18%', value: '200', time: '9:00 - 10:00', },
        { name: '唐冶街道', number: '18%', number1: '30%', number2: '20%', number3: '16%', number4: '16%', value: '200', time: '9:00 - 10:00', },
        { name: '董家街道', number: '30%', number1: '20%', number2: '16%', number3: '16%', number4: '18%', value: '200', time: '9:00 - 10:00', },
        { name: '鲍山街道', number: '30%', number1: '20%', number2: '16%', number3: '16%', number4: '18%', value: '200', time: '9:00 - 10:00', },
        { name: '港沟街道', number: '18%', number1: '16%', number2: '30%', number3: '20%', number4: '16%', value: '200', time: '9:00 - 10:00', },

      ];

      let geoCoordMap = {
        王舍人街道: [117.153474, 36.727325],
        山大路街道: [117.062716, 36.678972],
        洪家楼街道: [117.064251, 36.690416],
        东风街道: [117.087849, 36.694],
        全福街道: [117.063592, 36.704968],
        华山街道: [117.058558, 36.722672],
        荷花街道: [117.11318, 36.761699],
        唐冶街道: [117.237673, 36.69197],
        董家街道: [117.246539, 36.754268],
        鲍山街道: [117.196233, 36.721951],
        港沟街道: [117.21234, 36.681239]
      };
      let convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {

          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              number: data[i].number,
              number1: data[i].number1,
              number2: data[i].number2,
              number3: data[i].number3,
              number4: data[i].number4,
              time: data[i].time,
              outNumber: data[i].value
            });
          }
        }
        console.log(res, 555)
        return res;
      };
      function renderItem(params, api) {
        var coords = [
          [116.7, 39.53],
          [103.73, 36.03],
          [112.91, 27.87],
          [120.65, 28.01],
          [119.57, 39.95]
        ];
        var points = [];
        for (var i = 0; i < coords.length; i++) {
          points.push(api.coord(coords[i]));
        }
        var color = api.visual('color');
        return {
          type: 'polygon',
          shape: {
            points: echarts.graphic.clipPointsByRect(points, {
              x: params.coordSys.x,
              y: params.coordSys.y,
              width: params.coordSys.width,
              height: params.coordSys.height
            })
          },
          style: api.style({
            fill: color,
            stroke: echarts.color.lift(color)
          })
        };
      }

      this.mapOption = {
        backgroundColor: 'transparent',
        title: {
          text: '',
          // subtext: 'data from PM25.in',
          // sublink: 'http://www.pm25.in',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'click',
          borderColor: "rgba(255,255,255,0)",//'#3B708F', // 设置 tooltip 边线颜色
          backgroundColor: "rgba(255,255,255,0)",//'rgba(0,44,58, 0.0)', // 设置 tooltip 的背景颜色
          textStyle: {
            color: '#B1D6F1', // 设置 tooltip 文本的颜色
          },
          axisPointer: {
            type: 'none' // 'line' 直线指示器 'shadow' 阴影指示器'none' 无指示器'cross' 十字准星指示器
          },
          enterable: true,
          formatter: function (params) {
            var str = '';
            //首先要看看params是怎样的数据结构，里面有什么内容;
            console.log(params);
            //对params进行操作
            // params.forEach(item=>{
            //     str += item.name+":"+item.value[3]+"</br>"
            // })
            str = `<div style="width:400px;min-height:50px;cursor: pointer;text-align: left;
padding:5px ; box-shadow: 0 0 1px #fff inset;background: linear-gradient(to left, #F4E925, #F4E925) left top no-repeat, linear-gradient(to bottom, #F4E925, #F4E925) left top no-repeat, linear-gradient(to left, #F4E925, #F4E925) right top no-repeat, linear-gradient(to bottom, #F4E925, #F4E925) right top no-repeat, linear-gradient(to left, #F4E925, #F4E925) left bottom no-repeat, linear-gradient(to bottom, #F4E925, #F4E925) left bottom no-repeat, linear-gradient(to left, #F4E925, #F4E925) right bottom no-repeat, linear-gradient(to left, #F4E925, #F4E925) right bottom no-repeat;
background-size: 2px 10px, 10px 2px, 2px 10px, 10px 2px;transition: all .4s ease;border: 1px solid transparent;
position: absolute;left: 0px;top: 10px;color: white;letter-spacing: 0.1em;">
<div style="padding:30px 10px;background:rgba(17,53,91,0.7);  ">

  <div style="display:flex;justify-content: space-around; font-size: 17px;  ">
    <div>12345 热点</div>
		<div>历接历办热点</div>
<div>微信群预警热点</div>
    </div>
    <div  style="display:flex; margin-top:2%;margin-bottom:2%">
      <div style="margin-left:4%">(1)违章停车</div>
      <div style="margin-left:10%">(1)城乡建设</div>
      <div  style="margin-left:8%">(1)自然资源</div>
    </div>
    <div  style="display:flex;margin-bottom:2%">
      <div style="margin-left:4%">(2)房屋拆迁</div>
      <div style="margin-left:10%">(2)医疗保障</div>
      <div style="margin-left:8%">(2)组织人事</div>
    </div>
    <div  style="display:flex; ">
      <div style="margin-left:4%">(3)公共安全</div>
      <div style="margin-left:10%">(3)交通运输</div>
      <div style="margin-left:8%">(3)劳动和社会保障</div>
    </div>

</div>

</div>
`
{/* <div >地点:${params.data.name}</div> */}

            return str
          }
        },
        bmap: {
          center: [117.07145, 36.685656],
          zoom: 13,
          roam: true,
          mapStyle: {
            styleJson: styleJson
          }
        },
        series: [
          {
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'bmap',
            data: convertData(
              data
                .sort(function (a, b) {
                  return b.value - a.value;
                })
                .slice(0)
            ),
            encode: {
              value: 2
            },
            symbolSize: function (val) {
              return val[2] / 10;
            },
            showEffectOn: 'emphasis',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              formatter: '{b}',
              position: 'right',
              show: true
            },
            itemStyle: {
              color: '#f4e925',
              shadowBlur: 10,
              shadowColor: '#333'
            },
            zlevel: 1
          },
        ]
      };

      this.mapOption && this.mapChart.setOption(this.mapOption);
      let dom = document.querySelector("#mapCom.vue");
      let a = dom.firstElementChild
      if (a) {
        a.style.padding = '0px';
        console.log(a)

      }
    }
  }

}
</script>

<style lang="scss" scoped>
#mapCom {
  width: 100%;
  height: 100%;
 
}
</style>
