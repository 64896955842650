<template>
  <div class="petitionLeft">
    <!-- 信访事件趋势分析-->
    <div class="eventCategory">
      <div class="title">
        <h1>信访事件趋势分析</h1>
      </div>
      <div id="eventCategoryCom"></div>
    </div>
    <!-- 街道信访量-->
    <div class="streetTrafficValue">
      <div class="title">
        <h1>街道信访量</h1>
      </div>
      <div id="streetTrafficValueCom">
        <div class="table">
          <div class="thead">
            <p>名称</p>
            <p>数量</p>
            <p>排名</p>
          </div>
          <vue-seamless-scroll :data="streetTrafficValueList" class="tbodysh" :class-option="classOption">
            <div class="flex">
              <div v-for="(item, index) in streetTrafficValueList" :key="index" :class="index % 2 != 0 ? 'even' : ''">
                <p>{{ item.name }}</p>
                <p>{{ item.number }}</p>
                <p>
                  <span :class="item.ranking > 3 ? 'ranking4' : 'ranking' + item.ranking">
                    {{ item.ranking }}
                  </span>
                </p>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>


    <!-- 信访类别缘由排名 -->
    <div class="petitionCategory">
      <div class="title">
        <h1>信访事件类型排名</h1>
      </div>
      <div id="petitionCategoryCom">
        <div class="table">
          <div class="thead">
            <p>名称</p>
            <p>数量</p>
            <p>排名</p>
          </div>
          <vue-seamless-scroll :data="petitionCategoryList" class="tbodysh" :class-option="classOption">
            <div class="flex">
              <div v-for="(item, index) in petitionCategoryList" :key="index" :class="index % 2 != 0 ? 'even' : ''">
                <p>{{ item.name }}</p>
                <p>{{ item.number }}</p>
                <p>
                  <span :class="item.ranking > 3 ? 'ranking4' : 'ranking' + item.ranking">
                    {{ item.ranking }}
                  </span>
                </p>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';

import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

export default {
  name: "petitionLeft",
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      //事项类别实例和配置
      eventCategoryChart: null,
      eventCategoryOption: null,
      // 街道信访量排名数据
      streetTrafficValueList: [
        {
          name: '王舍人街道',
          number: '1522',
          ranking: '1'
        }, {
          name: '全福街道',
          number: '1033',
          ranking: '2'
        }, {
          name: '东风街道',
          number: '810',
          ranking: '3'
        }, {
          name: '鲍山街道',
          number: '716',
          ranking: '4'
        }, {
          name: '华山街道',
          number: '608',
          ranking: '5'
        }, {
          name: '唐王街道',
          number: '468',
          ranking: '6'
        }, {
          name: '港沟街道',
          number: '460',
          ranking: '7'
        }, {
          name: '郭店街道',
          number: '328',
          ranking: '8'
        }, {
          name: '洪家楼街道',
          number: '321',
          ranking: '9'
        }, {
          name: '彩石街道',
          number: '247',
          ranking: '10'
        }, {
          name: '董家街道',
          number: '239',
          ranking: '11'
        }, {
          name: '唐冶街道',
          number: '181',
          ranking: '12'
        }, {
          name: '山大路街道',
          number: '146',
          ranking: '13'
        }, {
          name: '荷花街道',
          number: '123',
          ranking: '14'
        },
      ],
      //信访类别排名数据
      petitionCategoryList: [
        {
          name: '民生服务',
          number: '52111',
          ranking: '1'
        }, {
          name: '住建',
          number: '36415',
          ranking: '2'
        },{
          name: '劳动和社会保障',
          number: '13461',
          ranking: '3'
        }, {
          name: '社会治安',
          number: '10120',
          ranking: '4'
        }
      ]
    }
  },
  computed: {
    // 街道信访量配置
    classOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  created() {
    this.$nextTick(() => {
      this.initEventCategory();

    })
  },
  methods: {
    // 事项类别
    initEventCategory() {
      let chartDom = document.getElementById('eventCategoryCom');
      this.eventCategoryChart = echarts.init(chartDom);
      this.eventCategoryOption = {
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '20%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '4月', '6月', '8月', '10月'],
          axisLabel: {
            textStyle: {
              color: '#9db2bc',
              fontSize: 12
            }
          },

        },
        yAxis: {
          name: '件',
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#9db2bc',
              fontSize: 12
            }
          },
          nameTextStyle: {
            color: '#9AB7CD', // 设置 y 轴名称的颜色
          },
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330],
            type: 'line',
            smooth: true,
            color: '#91d5ff', //线条颜色
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#559de9'  // 100% 处的颜色
                }, {
                  offset: 1, color: '#0a214a' //   0% 处的颜色
                }],
                global: false // 缺省为 false
              },

            }
          }
        ]
      };

      this.eventCategoryOption && this.eventCategoryChart.setOption(this.eventCategoryOption);
    },

  }
}
</script>

<style lang="scss" scoped>
.petitionLeft {
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  height: calc(100% - 20px);

  //background: rgba(40,110, 123, 0.2);
  //background: rgba(28,111, 126, 0.8);
  .eventCategory,
  .streetTrafficValue,
  .petitionCategory {
    background: rgba(28, 111, 126, 0.4);
    width: 100%;
    height: 32%;
  }

  #eventCategoryCom,
  #streetTrafficValueCom {
    width: 100%;
    height: calc(100% - 40px);
  }

  // 街道信访量和信访类别排名表格样式
  #streetTrafficValueCom,
  #petitionCategoryCom {
    .table {
      height: 100%;
    }

    .table .thead {
      background-color: #024562;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      margin-bottom: 10px;
    }

    .table .thead p {
      color: #fff;
    }

    .table p {
      text-align: center;
      color: #9db2bc;
      font-size: 14px;
    }

    .table .tbodysh {
      overflow: hidden;
      height: 180px !important;
    }

    .table .tbodysh .even {
      background: url(../../../assets/tableBack.png) no-repeat;
      background-size: 100% 100%;
    }

    .table .tbodysh .flex div {
      display: flex;
      align-items: center;
      padding: 10px 0;
      justify-content: space-between;
    }

    .table .thead p:nth-child(1),
    .table .tbodysh p:nth-child(1) {
      width: 35%;
    }

    .table .thead p:nth-child(2),
    .table .tbodysh p:nth-child(2) {
      width: 35%;
    }

    .table .thead p:nth-child(3),
    .table .tbodysh p:nth-child(3) {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto;
        border-radius: 45px;
      }
    }
  }

  //  排名样式
  .ranking1 {
    background-color: #b53e44;
    color: #fff;
  }

  .ranking2 {
    background-color: #be8a3f;
    color: #fff;
  }

  .ranking3 {
    background-color: #c6c23a;
    color: #fff;
  }

  .ranking4 {
    background-color: #3e80bc;
    color: #fff;
  }
}
</style>
