<!-- 数据大屏自适应组件模板 -->
<template id="dataComponent">
    <div class="outContainer" :style="{ height: boxHeight, width: boxWidth }">
        <div class="ScreenAdapter" :style="style">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    //参数注入
    props: {
        width: {
            type: String,
            default: '1920'
        },
        height: {
            type: String,
            default: '1080'
        }
    },
    data() {
        return {
            boxWidth: this.width,
            boxHeight: this.height,
            style: {
                width: this.width + 'px',
                height: this.height + 'px',
                transform: 'scale(1) translate(-50%, -50%)'
            }
        }
    },
    mounted() {
        this.setScale()
        window.onresize = this.Debounce(this.setScale, 200)
        // window.οnresize=this.setScale
    },
    destroyed() {
        window.removeEventListener("resize", this.Debounce(this.setScale, 200));
    },
    methods: {
        Debounce: (fn, t) => {
            const delay = t || 500
            let timer
            return function () {
                const args = arguments
                if (timer) {
                    clearTimeout(timer)
                }
                const context = this
                timer = setTimeout(() => {
                    timer = null
                    fn.apply(context, args)
                }, delay)
            }
        },
        // 获取放大缩小比例
        getScale() {
            //容器高度
            this.boxWidth = (document.body.clientWidth) + 'px'
            this.boxHeight = (document.body.clientHeight) + 'px'

            let w;
            w = (document.body.clientWidth) / this.width
            console.log(document.body.clientWidth);
            console.log(this.width);
            console.log(w,'缩放比例哈哈哈')
            return w
        },
        // 设置比例
        setScale() {
            this.style.transform = 'scale(' + this.getScale() + ') translate(0, 0)'

            //解决改变窗口大小时，意外出现滚动条问题
            this.$nextTick(() => {
                this.style.transform = 'scale(' + this.getScale() + ') translate(0, 0)'
            })
        },
    },
};
</script>

<style>
/*数据大屏组件样式*/
.ScreenAdapter {
    overflow-y: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    transform-origin: 0 0;
    position: absolute;
    left: 0;
    right: -17px;
    transition: 0.3s;
    /*padding: 20px 25px;*/
    box-sizing: border-box;
}

.ScreenAdapter::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.outContainer::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.outContainer {
    overflow-y: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    position: relative;
}
</style>
