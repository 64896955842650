import Vue from 'vue'
import App from './App.vue'
import router from './router/router'; // 引入router.js
import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);
Vue.use(dataV)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue();

new Vue({
    router, // 使用router
    render: h => h(App),
}).$mount('#app')
