// api.js
import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://institution.sdyingfeng.cn/prod-api/', // 设置接口的基础URL
    timeout: 5000, // 设置请求超时时间
});
// const apiClients = axios.create({
//     baseURL: 'http://scxinfang.tongxinxinxi.cn/api/', // 设置接口的基础URL
//     timeout: 5000, // 设置请求超时时间,
//     headers: {
//         Authorization: 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxMDEsInVzZXJfa2V5IjoiMTAxfHNjfHBjfDZkY2RjODBiLWEzMzEtNGNlOS05Y2EwLTAwNzNmNjNjNTA3MCIsInVzZXJuYW1lIjoiemhhbmdsZWlsZWkifQ.ptI76b3jUimP3J4vjLReBj3zhA1uuQ205nYg8kDdvlCjfvbqoigdjBvS31OWvXXUS5g4_mFn7J30uMg3xw2m5w',
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
//         'Access-Control-Allow-Headers': 'Content-Type, Authorization'
//     },
// });
export default {
    getData() {
        return apiClient.get('/api/api/frLegalPlatform/web/lawService/getTopFirm');
    },
    getData(params) {
        return apiClient.get('/service/personnel/largeList', { params });
    },
    Keygroups(params) {
        return apiClient.get('/service/group/largeList', { params });
    },
    serviceVisitsLargeList(params) {
        return apiClient.get('/service/visits/largeList', {
            params
        });
    },
    serviceVisitsLargeGetInfo(params) {
        return apiClient.get('/service/visits/largeGetInfo/' + params);
    },
    // 重点人详情
    keyPersonnelInfo(params){
        return apiClient.get('/service/personnel/largeGetInfo/'+params);
    },
    // 重点群详情
    keyInfo(params){
        return apiClient.get('/service/group/largeGetInfo/'+params);
    }
    // homePageTrackqueryImportantmember(params) {
    //     return apiClients.get('homePage/trackquery/importantmember', { params });
    // },

};
