<template>
  <div class="main-left">
    <div class="main-box-left">
      <div class="title">
        <h1>重点人员监测</h1>
        <div>
          <img src="@/assets/arrowsL.png" @click="prevRoll">
          <img src="@/assets/arrowsR.png" @click="nextRoll">
        </div>
      </div>
      <div class="main-l-keyareas">
        <div @click="infoRoll(item)" v-for="(item, index) in rollCallList" :key="index" class="main-l-keyareas-item">
          <div class="main-l-keyareas-item-box">
            <div class="keyareas-img">
              <img :src="item.headImg || require('@/assets/woman.png')" alt="">
              <span class="keyareas-name">{{ item.name }}</span>
            </div>
            <div class="keyareas-r">
              <div class="keyareas-num">
                <p>联系方式：</p>
                <span>
                  {{ item.phone }}
                  <!--                    <CountTo :startVal='0' :endVal='item.currentNum' :duration='6000' />-->
                </span>
              </div>
              <div class="keyareas-num">
                <p style="width:50px;">住址：</p>
                <span
                  style="width:calc(100% - 50px);display:block;overflow:hidden;text-overflow:ellipsis;white-space: nowrap">
                  {{ item.address }}
                  <!--                    <CountTo :startVal='0' :endVal='item.nextNum' :duration='6000' />-->
                </span>
              </div>
              <div class="keyareas-num">
                <p>重点等级：</p>
                <span
                  :style="item.riskLevel.indexOf('A') != -1 ? 'color:#b53e44' : item.riskLevel.indexOf('B') != -1 ? 'color:#be8a3f' : 'color:#3e80bc'">
                  {{ item.riskLevel }}
                  <!--                    <CountTo :startVal='0' :endVal='item.proportionNum' :duration='6000' />-->
                </span>
              </div>
              <div class="keyareas-num">
                <p>进展情况：</p>
                <span
                  style="width:calc(100% - 80px);display:block;overflow:hidden;text-overflow:ellipsis;white-space: nowrap">{{
                    item.progressOverview }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-box-left-cont">
      <div class="title">
        <h1>重点人群</h1>
        <div>
          <img src="@/assets/arrowsL.png" @click="prevKey">
          <img src="@/assets/arrowsR.png" @click="nextKey">
        </div>
      </div>
      <div class="main-early">
        <div @click="infoKey(item)" v-for="(item, index) in keyGroupsList" :key="index" class="main-early-item">
          <div class="main-early-item-box">
            <div class="early-img">
              <img src="../../../assets/baojing_1.png" alt="">
              <!-- <p>
                <span class="yellow">新预警</span>
                <span class="blue">未处理</span>
              </p> -->
            </div>
            <div class="earlyName">{{ item.name }}</div>
            <div class="earlyText">
              <p>事项类别：{{ item.eventCategory }} </p>
            </div>
            <div class="earlyText">
              <p>地点：{{ item.district }}</p>
            </div>
            <div class="earlyText">
              <p>时间：2023-10-3</p>
            </div>
            <div class="keyareas-problem">
              <p>主要问题：</p>
              <span>{{ item.mainIssues }}</span>

              <!--                            <span>详情 <i class="el-icon-arrow-right"></i></span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="tczdrymdpre" width="40%">
      <!-- <div v-if="tczdrymdpre"> -->
      <div style="padding:15px 5px;background:rgba(17,53,91,0.8);height:45vh;position:relative">
        <h1 style="text-align: center;font-size:20px;margin-bottom:20px">进度</h1>
        <div style="margin-bottom:20px;display:flex;justify-content: flex-start;align-items: center">
          <div style="width:calc(100% - 70px);display:flex;justify-content: space-evenly">
            <div @click="changeTypess(index + 1)" v-for="(item, index) in diaType" :key="index"
              style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
              <div class="earlyWarning" :style="keyActive == index + 1 ? 'background-color:#4186d3;' : ''"
                style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div v-show="keyActive == 1" class="earlyWarningBody">
          <div>
            <span style="color:rgb(139, 214, 241)">主要问题:</span>
            <span style="margin-left:5px">{{ inagurldzrq.mainIssues || '' }}</span>
          </div>
          <div style="margin-top:5px">
            <span style="color:rgb(139, 214, 241)">涉及人数:</span>
            <span style="margin-left:5px">{{ inagurldzrq.involvedPersonNum || '' }}</span>
          </div>
          <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">事项类别:</span>
            <span style="margin-left:5px;line-height:25px">{{ inagurldzrq.eventCategory || '' }}</span>
          </div>
        </div>
        <div v-show="keyActive == 2" class="studyBody" style="display:none;margin-top:30px">
          <!--                                <div style="white-space: wrap;margin-top:5px">-->
          <!--                                    <span style="color:rgb(139, 214, 241)">进展请款后果:</span>-->
          <!--                                    <span style="margin-left:5px">{{ inagurldzrq. || ''}}</span>-->
          <!--                                </div>-->
          <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">信访情况:</span>
            <span style="margin-left:5px;line-height:25px;">{{ inagurldzrq.petitionSituation || '' }}</span>
          </div>
          <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">户数:</span>
            <span style="margin-left:5px">{{ inagurldzrq.homeNum }}</span>
          </div>
        </div>
        <div v-show="keyActive == 3" class="dispatchBody" style="display:none;margin-top:30px">

          <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">工作专班:</span>
            <span style="line-height:25px;margin-left:5px">{{ inagurldzrq.workSpecialClass || '' }}</span>
          </div>
          <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">包案领导:</span>
            <span style="margin-left:5px">{{ inagurldzrq.packageLeader || '' }}</span>
          </div>
          <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">化解方案:</span>
            <span style="margin-left:5px;line-height:25px;">{{ inagurldzrq.resolutionPlan || '' }}</span>
          </div>
          <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">稳定措施:</span>
            <span style="margin-left:5px;line-height:25px;">{{ inagurldzrq.stabilizationMethod || '暂无措施' }}</span>
          </div>
        </div>
        <div v-show="keyActive == 4" class="secureBody" style="display:none;margin-top:30px">
          <div>
            <span style="color:rgb(139, 214, 241)">是否化解:</span>
            <span style="margin-left:5px">{{ inagurldzrq.isResolve || '否' }}</span>
          </div>
        </div>
        <div v-show="keyActive == 5"
          style="display:none;margin-top:30px;height:300px;position:relative;top:0;width:98%;border:1px solid rgba(16,46,79,0)">
          <div id="qushi1" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    <!-- </div> -->
    </el-dialog>
    <el-dialog :visible.sync="tczdrymdpres" width="40%">
      <div style="padding:15px 20px;background:rgba(17,53,91,0.8);height:45vh;position:relative">
        <div style="padding:15px 5px;background:rgba(17,53,91,0.5);">
          <div style="margin-bottom:20px;display:flex;justify-content:space-between;align-items: center;">
            <span>进度:</span>
            <div @click="changetype(1)"
              style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
              <div class="earlyWarning" :style="keyActives == 1 ? 'background-color:#4186d3;' : ''"
                style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">
                预警
              </div>
            </div>
            <div @click="changetype(2)"
              style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
              <div class="earlyWarning" :style="keyActives == 2? 'background-color:#4186d3;' : ''"
                style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">
                研判
              </div>
            </div>
            <div @click="changetype(3)"
              style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
              <div class="earlyWarning" :style="keyActives == 3? 'background-color:#4186d3;' : ''"
                style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">
                调度
              </div>
            </div>
            <div @click="changetype(4)"
              style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
              <div class="earlyWarning" :style="keyActives == 4? 'background-color:#4186d3;' : ''"
                style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">
                解除
              </div>
            </div>
            <div @click="changetype(5)"
              style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
              <div class="earlyWarning" :style="keyActives == 5? 'background-color:#4186d3;' : ''"
                style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">
                轨迹
              </div>
            </div>
          </div>
          <div class="earlyWarningBody" v-if="keyActives==1">
            <div>
              <span style="color:rgb(139, 214, 241)">姓名:</span>
              <span style="margin-left:5px">{{ params.name || '请选择' }}</span>
            </div>
            <div style="margin-top:5px">
              <span style="color:rgb(139, 214, 241)">身份证号:</span>
              <span style="margin-left:5px">{{ params.cardNo || '请选择' }}</span>
            </div>
            <div style="white-space: wrap;margin-top:5px">
              <span style="color:rgb(139, 214, 241)">反映诉求:</span>
              <span style="margin-left:5px;line-height:25px">{{ params.reflectDemands || '请选择' }}</span>
            </div>
            <div style="margin-top:5px">
              <span style="color:rgb(139, 214, 241)">事项类别:</span>
              <span style="margin-left:5px">{{ params.matterCategory || '请选择' }}</span>
            </div>
          </div>
          <div class="studyBody" v-if="keyActives==2">
            <div style="white-space: wrap;margin-top:5px">
              <span style="color:rgb(139, 214, 241)">住址:</span>
              <span style="margin-left:5px">{{ params.address || '请选择' }}</span>
            </div>
            <div style="white-space: wrap;margin-top:5px">
              <span style="color:rgb(139, 214, 241)">进展:</span>
              <span style="margin-left:5px;line-height:25px;">{{ params.progressOverview || '请选择' }}</span>
            </div>
            <div style="white-space: wrap;margin-top:5px">
              <span style="color:rgb(139, 214, 241)">风险等级:</span>
              <span style="margin-left:5px">{{ params.riskLevel || '请选择' }}</span>
            </div>
          </div>
          <div class="dispatchBody" v-if="keyActives==3">

            <div style="white-space: wrap;margin-top:5px">
              <span style="color:rgb(139, 214, 241)">专班负责人:</span>
              <span style="margin-left:5px">{{ params.specialShiftLeader || '请选择' }}</span>
            </div>
            <div style="white-space: wrap;margin-top:5px">
              <span style="color:rgb(139, 214, 241)">责任单位:</span>
              <span style="margin-left:5px">{{ params.responsibilityOrg || '请选择' }}</span>
            </div>
            <div style="white-space: wrap;margin-top:5px">
              <span style="color:rgb(139, 214, 241)">稳定措施:</span>
              <span style="margin-left:5px;line-height:25px;">{{ params.stabilizationMethod || '请选择' }}</span>
            </div>
          </div>
          <div class="secureBody" v-if="keyActives==4">
            <div>
              <span style="color:rgb(139, 214, 241)">是否化解:</span>
              <span style="margin-left:5px">{{ params.isResolve || '请选择' }}</span>
            </div>
          </div>
          <div class="trackBody" v-if="keyActives==5">
            <p style="background:#FEF0F0;width:100px;text-align:center;padding:10px 0;border-radius:10px;">
              <a href="http://szlc.tongxinxinxi.cn:8888/homePage/trackquery/importantmember?token=eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoyNzgsInVzZXJfa2V5IjoiMjc4fHNjfHBjfGVjNTM1MTc3LTc2ZGMtNDQ5NC05NjcxLTYwNjM4NDM5YWE5NyIsInVzZXJuYW1lIjoiZ2p6em1tIn0.gIQ2mBd8OwXX97phITfSAbTn4fxLgIRjMA6lKbyfM3672Uwigyu3ARwrZWmun6qRN7Y1B-kbPNbfZcELVvMlYg"
                target="_Blank">行动轨迹</a>
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import CountTo from "vue-count-to";
import api from "@/api/api";
import * as echarts from 'echarts';
export default {
  components: {
    CountTo,
  },
  data() {
    return {
      params: {},
      tczdrymdpres: false,
      tczdrymdpre: false,
      inagurldzrq: {},//群体详情数据
      // 重点人员名单
      rollCallList: [],
      rollTotal: 0,
      rollQuery: {
        pageNum: 1,
        pageSize: 6,
      },
      // 重点人群名单
      keyGroupsList: [],
      keyGroupTotal: 0,
      keyQuery: {
        pageNum: 1,
        pageSize: 7,
      },
      // 重点人员弹窗分类
      diaType: ['预警', '研判', '调度', '解除', '趋势'],
      // 重点人员dialog
      keyActive: 1,
      keyActives: 1
    }
  },
  created() {
    this.getData();
    this.Keygroups();
  },
  methods: {
    changetype(val){
      this.keyActives=val
    },
    renderChart1() {
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('qushi1'));
        // 指定图表的配置项和数据
        var option = {
          title: {
            text: '',
          },
          grid: {
            top: '35',
            left: '40',
            right: '20',
            bottom: '20',
          },
          tooltip: {
            trigger: 'axis',
            borderColor: '#3B708F', // 设置 tooltip 边线颜色
            backgroundColor: 'rgba(0,44,58, 0.9)', // 设置 tooltip 的背景颜色
            textStyle: {
              color: '#B1D6F1', // 设置 tooltip 文本的颜色
            },
          },
          legend: {
            // 图例配置
            data: ['预警率'], // 图例显示的文本
            textStyle: {
              color: '#608FB0', // 设置图例字体颜色
            },
          },
          xAxis: {
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月'],
            axisLine: {
              lineStyle: {
                color: '#4189AD', // 设置 x 轴线的颜色
              },
            },
            axisLabel: {
              textStyle: {
                color: '#9AB7CD', // 设置 x 轴标签字体颜色
              },
            },
          },
          yAxis: {
            name: '(%)',
            nameTextStyle: {
              color: '#9AB7CD', // 设置 y 轴名称的颜色
            },
            splitLine: {
              lineStyle: {
                color: '#184256', // 设置背景线的颜色
                type: 'dashed', // 设置虚线样式
              },
            },
            axisLabel: {
              textStyle: {
                color: '#9AB7CD', // 设置 x 轴标签字体颜色
              },
            },
          },
          series: [
            {
              name: '预警率',
              type: 'line',
              stack: 'Total2',
              data: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      });
    },
    // 改变重点人员弹窗type
    changeTypess(val) {
      this.keyActive = val
      this.renderChart1()
    },
    // 人员详情
    async infoRoll(item) {
      console.log(item);
      this.tczdrymdpres = true
      this.params = item
      let res = await api.keyPersonnelInfo(item.id);
      // this.$bus.$emit('showToop', item);
      this.$bus.$emit('specially', res.data.data, true)
    },
    // 重点群体详情
    async infoKey(item) {
      let res = await api.keyInfo(item.id)
      this.$bus.$emit('specially', res.data.data, false)
      this.tczdrymdpre = true;
      this.inagurldzrq = item
    },
    // 上一页
    prevRoll() {
      if (this.rollQuery.pageNum == 1) return false
      this.rollQuery.pageNum--;
      this.getData();
    },
    // 下一页
    nextRoll() {
      this.rollQuery.pageNum++;
      this.getData()
    },
    // 重点人员名单
    getData() {
      console.log(this.rollQuery)
      api
        .getData(this.rollQuery)
        .then((res) => {
          this.rollTotal = res.data.total;
          res.data.rows.forEach(item => {
            if (item.headImg) {
              item.headImg = 'https://institution.sdyingfeng.cn/prod-api' + item.headImg
            }
          })
          this.rollCallList = res.data.rows;
          console.log(this.rollCallList);
        });
    },
    // 上一页
    prevKey() {
      if (this.keyQuery.pageNum == 1) return false
      this.keyQuery.pageNum--;
      this.Keygroups();
    },
    // 下一页
    nextKey() {
      this.keyQuery.pageNum++
      this.Keygroups();
    },
    // 重点群体名单
    Keygroups() {
      api
        .Keygroups(this.keyQuery)
        .then((res) => {
          this.keyGroupTotal = res.data.total;
          this.keyGroupsList = res.data.rows;
          console.log('群体数据=', this.keyGroupsList)
        });
    },
  }

}
</script>

<style lang="scss" scoped>
.main-left {
  position: absolute;
  left: 20px;
  top: 10px;
  width: 34%;
  height: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-box-left {
  width: 49%;
  /*height: 100%;*/
  height: calc(100% - 10px);

}

.main-box-left-cont {
  margin-left: 15px;
  width: 50%;
  height: calc(100% - 10px);
  /*height: 100%;*/
}

.keyareas-img {
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;

  img {
    width: 55px;
    height: 55px;
  }

  .keyareas-name {
    width: 60px;
    font-size: 13px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.main-l-keyareas {
  width: 100%;
  height: calc(100% - 35px);
  /*height: 100%;*/
  overflow: auto;
}

.main-l-keyareas-item {
  //background: url(../../../assets/backCon.png) no-repeat;
  //  width:450px;
  //  min-height:50px;
  //text-align: left;
  // padding:5px ;

  //position: absolute;
  //left: 0px;top: 10px;

  //background-size: 100% 100%;
  cursor: pointer;
  box-shadow: 0 0 1px #fff inset;
  background: linear-gradient(to left, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat;
  background-size: 2px 10px, 10px 2px, 2px 10px, 10px 2px;
  transition: all .4s ease;
  border: 1px solid transparent;
  color: white;
  letter-spacing: 0.1em;
  margin: 5px 0;
  padding: 5px;
  box-sizing: border-box;

  .main-l-keyareas-item-box {
    width: 100%;
    height: 100%;
    background: rgba(17, 53, 91, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
    box-sizing: border-box;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    transition: all 0.5s ease;
  }
}

.keyareas-r {
  width: 78%;
}

.keyareas-num {
  width: 100%;
  font-size: 13px;
  line-height: 24px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-early {
  width: 100%;
  height: calc(100% - 30px);
  overflow: auto;
}

.main-early .early-img {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-early .early-img img {
  width: 20px;
}

.main-early .early-img p {
  font-size: 13px;
}

.main-early .early-img p span.yellow {
  color: #c99a2e;
  border: 1px solid #c99a2e;
  border-radius: 4px;
  padding: 2px;
  margin-right: 6px;
}

.main-early .early-img p span.blue {
  color: #00def0;
  border: 1px solid #00def0;
  border-radius: 4px;
  padding: 2px;
}

.main-early .earlyName {
  font-size: 14px;
  color: #fff;
  margin: 6px 0;
}

.main-early .earlyText {
  font-size: 13px;
  color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
}

.main-early-item {
  cursor: pointer;
  box-shadow: 0 0 1px #fff inset;
  background: linear-gradient(to left, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat;
  background-size: 2px 10px, 10px 2px, 2px 10px, 10px 2px;
  transition: all .4s ease;
  border: 1px solid transparent;
  color: white;
  letter-spacing: 0.1em;
  //background: url(../../../assets/backCon.png) no-repeat;
  //background-size: 100% 100%;
  margin: 5px 0;
  padding: 5px;
  box-sizing: border-box;

  &-box {
    background: rgba(17, 53, 91, 0.7);
    padding: 5px 1px;
    box-sizing: border-box;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    transition: all 0.5s ease;
  }
}

// 重点人群弹窗样式
::v-deep .el-dialog__wrapper {
  .el-dialog {
    width: 450px;
    min-height: 50px;
    cursor: pointer;
    text-align: left;
    padding: 5px;
    box-shadow: 0 0 1px #fff inset;
    background: linear-gradient(to left, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat;
    background-size: 2px 10px, 10px 2px, 2px 10px, 10px 2px;
    transition: all .4s ease;
    border: 1px solid transparent;
    color: white;
    letter-spacing: 0.1em;

    .el-dialog__header {
      padding: 0;

    }

    .el-dialog__body {
      color: #fff;
      padding: 0;

      .earlyWarningBody {
        margin-top: 30px;
      }
    }
  }
}

//主要问题
.keyareas-problem {
  display: flex;
  color: #fff;
  font-size: 13px;
  margin: 0 5px;

  span {
    width: calc(100% - 75px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }
}
</style>
