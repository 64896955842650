import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    routes: [{
            path: '/',
            redirect: '/index',
            // name: 'index',
            // component: () => import('../components/index.vue'),
            // meta: {
            //   title: '历城信访智慧调度中心',
            // }, // 设置页面标题
        },
        {
            path: '/index',
            name: 'Index',
            component: () =>
                import ('../components/index.vue'),
            meta: {
                title: '历城信访智慧调度中心',
            }, // 设置页面标题
        },
        {
            path: '/petitionStreet',
            name: 'PetitionStreet',
            component: () =>
                import ('../components/petitionStreet/index.vue'),
            meta: {
                title: '历城信访智慧调度中心',
            },
        }, {
            path: '/about',
            name: 'About',
            component: () =>
                import ('../components/about/about.vue'),
            meta: {
                title: '历城信访智慧调度中心'
            }
        }, {
            path: '/xfdt',
            name: 'xfdt',
            component: () =>
                import ('../components/xfdt.vue'),
            meta: {
                title: '历城信访智慧调度中心'
            }
        }
    ],
});

// 在路由导航前更新页面标题
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router;