<template>
  <div id="mapCom"></div>
</template>

<script>
import * as echarts from 'echarts/core';
import { TitleComponent, TooltipComponent } from 'echarts/components';
import { ScatterChart, EffectScatterChart, CustomChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/extension/bmap/bmap';

echarts.use([
  TitleComponent,
  TooltipComponent,
  ScatterChart,
  EffectScatterChart,
  CustomChart,
  CanvasRenderer,
  UniversalTransition
]);

import styleJson from './styleJson'
export default {
  data() {
    return {
      // 地图显示的街道
      data: [
        { name: '山大路街道', number: 109, value: '200', time: '9:00 - 10:00', },
        { name: '洪家楼街道', number: 511, value: '200', time: '9:00 - 10:00', },
        { name: '东风街道', number: 466, value: '200', time: '9:00 - 10:00', },
        { name: '全福街道', number: 509, value: '200', time: '9:00 - 10:00', },
        { name: '华山街道', number: 730, value: '200', time: '9:00 - 10:00', },
        { name: '荷花街道', number: 415, value: '200', time: '9:00 - 10:00' },
        { name: '王舍人街道办事处', number: 161, value: '200', time: '9:00 - 10:00', },
        { name: '唐冶街道', number: 840, value: '200', time: '9:00 - 10:00', },
        { name: '董家街道', number: 345, value: '200', time: '9:00 - 10:00', },
        { name: '鲍山街道', number: 621, value: '200', time: '9:00 - 10:00', },
        { name: '港沟街道', number: 425, value: '200', time: '9:00 - 10:00', },
      ],
      // 地图实例
      mapChart: null,
      // 地图配置
      mapOption: null,
      // 地图显示的街道坐标
      geoCoordMap: {
        王舍人街道办事处: [117.153474, 36.727325],
        山大路街道: [117.062716, 36.678972],
        洪家楼街道: [117.064251, 36.690416],
        东风街道: [117.087849, 36.694],
        全福街道: [117.063592, 36.704968],
        华山街道: [117.058558, 36.722672],
        荷花街道: [117.11318, 36.761699],
        唐冶街道: [117.237673, 36.69197],
        董家街道: [117.246539, 36.754268],
        鲍山街道: [117.196233, 36.721951],
        港沟街道: [117.21234, 36.681239]
      }
    }
  },
  created() {
    this.$nextTick(() => {
      // 正式key lTsBFgRDtiyBpVljvBYkrXNju8v2MoX1
      this.loadBMap('CfGlypDwTelWikV9cxqCG6lG5dkElpt1').then(() => {
        this.convertData(this.data);
        this.initMap()
      }).catch(() => {
      })
    })
    // 点击人员出现弹窗
    this.$bus.$on('showToop', (val) => {
      let iArr = []
      this.data.forEach(item => {
        iArr.push(item.name);
      })
      // 查找点击的个人对应的街道,将数据放到街道上
      let i = iArr.indexOf(val.responsibilityOrg);
      this.data[i].pName = val.name;
      this.data[i].headImg = val.headImg;
      this.data[i].cardNo = val.cardNo;
      this.data[i].reflectDemands = val.reflectDemands
      this.data[i].matterCategory = val.matterCategory
      this.data[i].progressOverview = val.progressOverview
      this.data[i].address = val.address
      this.data[i].riskLevel = val.riskLevel
      this.data[i].workTeamMember = val.workTeamMember
      this.data[i].specialShiftLeader = val.specialShiftLeader
      this.data[i].responsibilityOrg = val.responsibilityOrg
      this.data[i].stabilizationMethod = val.stabilizationMethod
      this.data[i].isResolve = val.isResolve
      // 刷新实例
      this.convertData(this.data);

      // 动态显示弹窗数据
      this.$nextTick(() => {
        // 刷新实例
        this.mapOption.series[0].data = this.data;
        // 重新加载实例
        this.mapChart.setOption(this.mapOption);
        // 手动打开弹窗
        this.mapChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: i
          // position: 'top'
        });
      })
    })

    //     切换弹窗tab
    function changetype(val) {
      // 点击弹窗tab时去掉其他全部背景颜色,隐藏其他分类内容
      let earlyWarning = document.querySelector('.earlyWarning')
      let study = document.querySelector('.study');
      let dispatch = document.querySelector('.dispatch')
      let secure = document.querySelector('.secure');
      let track = document.querySelector('.track');
      let earlyWarningBody = document.querySelector('.earlyWarningBody');
      let studyBody = document.querySelector('.studyBody');
      let dispatchBody = document.querySelector('.dispatchBody');
      let secureBody = document.querySelector('.secureBody');
      let trackBody = document.querySelector('.trackBody');
      study.style.backgroundColor = '';
      dispatch.style.backgroundColor = '';
      secure.style.backgroundColor = '';
      earlyWarning.style.backgroundColor = '';
      track.style.backgroundColor = '';
      earlyWarningBody.style.display = 'none';
      studyBody.style.display = 'none';
      dispatchBody.style.display = 'none';
      secureBody.style.display = 'none';
      trackBody.style.display = 'none';

      // 将点击的那个tab分类赋值颜色并且显示对应内容
      switch (val) {
        case 1:
          earlyWarning.style.backgroundColor = '#4186d3'
          earlyWarningBody.style.display = 'block';
          break;
        case 2:
          study.style.backgroundColor = '#4186d3'
          studyBody.style.display = 'block';
          break;
        case 3:
          dispatch.style.backgroundColor = '#4186d3';
          dispatchBody.style.display = 'block';
          break;
        case 4:
          secure.style.backgroundColor = '#4186d3';
          secureBody.style.display = 'block';
          break;
        case 5:
          track.style.backgroundColor = '#4186d3';
          trackBody.style.display = 'block';
          break;
      }
    }
    // js添加全局事件
    window.changetype = changetype
  },

  methods: {
    // 加载百度key
    loadBMap(ak) {
      return new Promise(function (resolve, reject) {
        if (typeof BMap !== 'undefined') {
          resolve(BMap)
          return true
        }
        window.onBMapCallback = function () {
          resolve(BMap)
        }
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://api.map.baidu.com/api?v=3.0&ak=' + ak + '&callback=onBMapCallback'
        // script.src = 'https://api.map.baidu.com/api?v=2.0&ak=' + ak + '&__ec_v__=20190126&callback=onBMapCallback'
        script.onerror = reject
        document.head.appendChild(script)
      })
    },
    // 给对应街道添加个人信息,来进行点击查看个人信息
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
            number: data[i].number,
            time: data[i].time,
            outNumber: data[i].value,
            pName: data[i].pName,
            headImg: data[i].headImg,
            cardNo: data[i].cardNo,
            reflectDemands: data[i].reflectDemands,
            matterCategory: data[i].matterCategory,
            address: data[i].address,
            progressOverview: data[i].progressOverview,
            riskLevel: data[i].riskLevel,
            workTeamMember: data[i].workTeamMember,
            specialShiftLeader: data[i].specialShiftLeader,
            responsibilityOrg: data[i].responsibilityOrg,
            stabilizationMethod: data[i].stabilizationMethod,
            isResolve: data[i].isResolve
          });
        }
      }
      console.log('res是多少', res)
      // return res;
      this.data = res;
    },
    // 初始化地图
    initMap() {
      let chartDom = document.getElementById('mapCom');
      this.mapChart = echarts.init(chartDom);


      this.mapOption = {
        backgroundColor: 'transparent',
        title: {
          text: '',
          // subtext: 'data from PM25.in',
          // sublink: 'http://www.pm25.in',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: false, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: false }
          },
          itemSize: 20,
          right: '24%',
          top: '10%',
          orient: 'vertical',
          iconStyle: {
            borderColor: '#fff'
          }
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'click',
          borderColor: "rgba(255,255,255,0)",//'#3B708F', // 设置 tooltip 边线颜色
          backgroundColor: "rgba(255,255,255,0)",//'rgba(0,44,58, 0.0)', // 设置 tooltip 的背景颜色
          textStyle: {
            color: '#B1D6F1', // 设置 tooltip 文本的颜色
          },
          // alwaysShowContent:true,                     //是否永远显示提示框内容
          axisPointer: {
            type: 'none' // 'line' 直线指示器 'shadow' 阴影指示器'none' 无指示器'cross' 十字准星指示器
          },
          enterable: true,
          formatter: function (params) {
            var str = '';
            //首先要看看params是怎样的数据结构，里面有什么内容;
            console.log(params);
            //对params进行操作
            // params.forEach(item=>{
            //     str += item.name+":"+item.value[3]+"</br>"
            // })
            // 弹窗样式
            str = `<div style="width:450px;min-height:50px;cursor: pointer;text-align: left;
padding:5px ; box-shadow: 0 0 1px #fff inset;background: linear-gradient(to left, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) right top no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to bottom, #6fbfe7, #6fbfe7) left bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat, linear-gradient(to left, #6fbfe7, #6fbfe7) right bottom no-repeat;
background-size: 2px 10px, 10px 2px, 2px 10px, 10px 2px;transition: all .4s ease;border: 1px solid transparent;
position: absolute;left: 0px;top: 10px;color: white;letter-spacing: 0.1em;">
<div style="padding:15px 5px;background:rgba(17,53,91,0.5);">
    <div style="margin-bottom:20px;display:flex;justify-content: flex-start;align-items: center">
        <span>进度:</span>
        <div style="width:calc(100% - 70px);display:flex;justify-content: space-evenly">
            <div  onclick="changetype(1)" style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
                <div class="earlyWarning" style="width:45px;height:45px;margin:2.5px auto;background-color:#4186d3;border-radius:45px;line-height:45px">预警</div>
            </div>
           <div onclick="changetype(2)"  style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
                <div  class="study" style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">研判</div>
            </div>
             <div  onclick="changetype(3)"  style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
                <div class="dispatch" style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">调度</div>
            </div>
             <div onclick="changetype(4)"  style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
                <div class="secure" style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">解除</div>
            </div>
            <div onclick="changetype(5)"  style="width:50px;height:50px;border:1px solid #fff;text-align:center;margin-left:5px;border-radius:45px;">
                <div class="track" style="width:45px;height:45px;margin:2.5px auto;border-radius:45px;line-height:45px">轨迹</div>
            </div>
        </div>

    </div>
    <div class="earlyWarningBody">
       <div>
            <span style="color:rgb(139, 214, 241)">姓名:</span>
            <span style="margin-left:5px">${params.data.pName || '请选择'}</span>
       </div>
       <div style="margin-top:5px">
            <span style="color:rgb(139, 214, 241)">身份证号:</span>
            <span style="margin-left:5px">${params.data.cardNo || '请选择'}</span>
       </div>
        <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">反映诉求:</span>
            <span style="margin-left:5px;line-height:25px">${params.data.reflectDemands || '请选择'}</span>
        </div>
        <div style="margin-top:5px">
            <span style="color:rgb(139, 214, 241)">事项类别:</span>
            <span style="margin-left:5px">${params.data.matterCategory || '请选择'}</span>
        </div>
    </div>
      <div class="studyBody" style="display:none">
       <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">住址:</span>
            <span style="margin-left:5px">${params.data.address || '请选择'}</span>
       </div>
       <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">进展:</span>
            <span style="margin-left:5px;line-height:25px;">${params.data.progressOverview || '请选择'}</span>
       </div>
        <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">风险等级:</span>
            <span style="margin-left:5px">${params.data.riskLevel || '请选择'}</span>
        </div>
    </div>
      <div class="dispatchBody" style="display:none">

       <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">专班负责人:</span>
            <span style="margin-left:5px">${params.data.specialShiftLeader || '请选择'}</span>
       </div>
        <div style="white-space: wrap;margin-top:5px">
            <span style="color:rgb(139, 214, 241)">责任单位:</span>
            <span style="margin-left:5px">${params.data.responsibilityOrg || '请选择'}</span>
        </div>
        <div style="white-space: wrap;margin-top:5px">
            <span  style="color:rgb(139, 214, 241)">稳定措施:</span>
            <span style="margin-left:5px;line-height:25px;">${params.data.stabilizationMethod || '请选择'}</span>
        </div>
    </div>
      <div class="secureBody" style="display:none">
       <div>
            <span style="color:rgb(139, 214, 241)">是否化解:</span>
            <span style="margin-left:5px">${params.data.isResolve || '请选择'}</span>
       </div>
    </div>
    <div class="trackBody" style="display:none">
      <p style="background:#FEF0F0;width:100px;text-align:center;padding:10px 0;border-radius:10px;">
            <a href="http://szlc.tongxinxinxi.cn:8888/homePage/trackquery/importantmember?token=eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoyNzgsInVzZXJfa2V5IjoiMjc4fHNjfHBjfGVjNTM1MTc3LTc2ZGMtNDQ5NC05NjcxLTYwNjM4NDM5YWE5NyIsInVzZXJuYW1lIjoiZ2p6em1tIn0.gIQ2mBd8OwXX97phITfSAbTn4fxLgIRjMA6lKbyfM3672Uwigyu3ARwrZWmun6qRN7Y1B-kbPNbfZcELVvMlYg"
              target="_Blank">行动轨迹</a>
            </p>
    </div>
</div>
</div>
`
            return str
          }
        },
        bmap: {
          center: [117.07145, 36.685656],
          zoom: 13,
          roam: true,
          mapStyle: {
            styleJson: styleJson
          }
        },
        series: [
          {
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'bmap',
            data: this.data,
            encode: {
              value: 2
            },
            symbolSize: function (val) {
              return val[2] / 10;
            },
            showEffectOn: 'emphasis',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              formatter: '{b}',
              position: 'right',
              show: true
            },
            itemStyle: {
              color: '#f4e925',
              shadowBlur: 10,
              shadowColor: '#333'
            },
            zlevel: 1
          },
        ]
      };
      this.mapOption && this.mapChart.setOption(this.mapOption);
      let dom = document.querySelector("#mapCom.vue");
      let a = dom.firstElementChild
      if (a) {
        a.style.padding = '0px';
        console.log(a)

      }
    }
  }
}
</script>

<style lang="scss" scoped>
#mapCom {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
